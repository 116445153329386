 var BrowserDetect = {
	init: function () {
		this.browser = this.searchString(this.dataBrowser) || "an unknown browser";
		this.version = this.searchVersion(navigator.userAgent)
			|| this.searchVersion(navigator.appVersion)
			|| "an unknown version";
		this.OS = this.searchString(this.dataOS) || "an unknown OS";
	},
	searchString: function (data) {
		for (var i=0;i<data.length;i++)	{
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			this.versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) != -1)
					return data[i].identity;
			}
			else if (dataProp)
				return data[i].identity;
		}
	},
	searchVersion: function (dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index == -1) return;
		return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
	},
	isSupportedMobileDevice: function () {
		switch( BrowserDetect.OS ) {
			case "iPhone/iPod":
				return true;
				break;
			case "Android":
				if( this.getAndroidVersion < 2.2 ) {
					return false;
				}
				return true;
				break;
			case "WindowsPhone":
				return true;
				break;
			default:
				break;
		}
		return false;
	},
	showBrowserErrors: function() {
		switch( this.OS ) {
			case "Mac":
			case "Win":
			case "Windows":
			case "Linux":
				if( this.browser === 'Explorer' && this.version < 9 ) {
					this.displayNotSupported( 'explorer' );
				}
				break;
			case "iPhone/iPod":
				if( this.version < 5.0 ) {
					this.displayNotSupported( 'ios-version' );
				}
				break;
			case "Android":
				// if( this.browser !== 'Chrome' ) {
				// 	displayNotSupported( 'chrome-android' );
				// }
				if( this.getAndroidVersion() < 2.2 ) {
				 	this.displayNotSupported( 'android-version' );
				}
				break;
			case "WindowsPhone":
			break;
			default:
				//displayNotSupported();
				break;
		}
	},
	isSupportedDevice: function() {
		switch( this.OS ) {
			case "Mac":
			case "Win":
			case "Windows":
			case "Linux":
				break;
			case "iPhone/iPod":
				if( this.version < 5.0 ) {
					return false;
				} 
				break;
			case "Android":
				if( this.version < 4.0 ) {
					return false;
				}
				break;
			case "WindowsPhone":
				break;
			default:
				break;
		}
		return true;
	},
	isSmartphone: function() {
		a = navigator.userAgent||navigator.vendor||window.opera;
		if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) {
			return true;
		}
		return false;
	},
	displayNotSupported: function( name ) {
		if( typeof( name ) === 'undefined' ) name = 'general';

		var errorDiv = document.getElementById( 'error-overlay' );
		if( errorDiv ) {
			errorDiv.setAttribute( 'class', 'active error-' + name );
		}

		if(_gaq) {
			_gaq.push(['_setCustomVar',
				1,					// This custom var is set to slot #1
				'Not Supported',	// The name of the custom variable
				name,				// Sets the value 
				2 					// Sets the scope to session-level. Optional
			]);
		}
	},
	isFirefox: function() {
		return (this.browser === 'Firefox');
	},
	isIe10Mobile: function() {
		return (navigator.userAgent.match(/IEMobile\/10\.0/));
	},
	isIe10: function() {
		return (this.browser === 'Explorer' && this.version === 10);
	},
	isAndroidLt4: function() {
		return (this.isAndroid() && this.getAndroidVersion() < 4.0);
	},
	isAndroid: function() {
		return (this.OS === "Android" );
	},
	getAndroidVersion: function() {
		var androidVersion = false;
		if( this.isAndroid() ) {
	  		androidVersion = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android")+8)); 
		}
		return androidVersion;
	},
	isIOs: function() {
		return (this.OS === 'iPhone/iPod' || this.OS === 'iPad');
	},
	getIOsVersion: function() {
		if( this.isIOs() ) {
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
	    	return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];	
		}
	},
	dataBrowser: [
		{
			string: navigator.userAgent,
			subString: "Chrome",
			identity: "Chrome"
		},
		{ 	string: navigator.userAgent,
			subString: "OmniWeb",
			versionSearch: "OmniWeb/",
			identity: "OmniWeb"
		},
		{
			string: navigator.vendor,
			subString: "Apple",
			identity: "Safari",
			versionSearch: "Version"
		},
		{
			prop: window.opera,
			identity: "Opera",
			versionSearch: "Version"
		},
		{
			string: navigator.vendor,
			subString: "iCab",
			identity: "iCab"
		},
		{
			string: navigator.vendor,
			subString: "KDE",
			identity: "Konqueror"
		},
		{
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		},
		{
			string: navigator.vendor,
			subString: "Camino",
			identity: "Camino"
		},
		{		// for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: "Netscape",
			identity: "Netscape"
		},
		{
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer",
			versionSearch: "MSIE"
		},
		{
			string: navigator.userAgent,
			subString: "Gecko",
			identity: "Mozilla",
			versionSearch: "rv"
		},
		{ 		// for older Netscapes (4-)
			string: navigator.userAgent,
			subString: "Mozilla",
			identity: "Netscape",
			versionSearch: "Mozilla"
		}
	],
	dataOS : [
		{
			string: navigator.userAgent,
			subString: "Windows Phone",
			identity: "WindowsPhone"
		},
		{
			string: navigator.platform,
			subString: "Win",
			identity: "Windows"
		},
		{
			string: navigator.platform,
			subString: "Mac",
			identity: "Mac"
		},
		{
			   string: navigator.userAgent,
			   subString: "iPhone",
			   identity: "iPhone/iPod"
	    },
		{
			   string: navigator.userAgent,
			   subString: "iPad",
			   identity: "iPad"
	    },
		{
			   string: navigator.userAgent,
			   subString: "BlackBerry",
			   identity: "Blackberry"
	    },
		{
			   string: navigator.userAgent,
			   subString: "Android",
			   identity: "Android"
	    },
		{
			string: navigator.platform,
			subString: "Linux",
			identity: "Linux"
		}
	]

};
